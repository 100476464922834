import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EntityLink from '../../../components/modularcomponents/entitylink/entitylink';
import HeaderTitle from '../../../components/modularcomponents/header/headertitle';
import { Nullable, PossibleAction, Strategy } from '../../../types/types';
import HeaderProperty from '../../../components/modularcomponents/header/headerproperty';
import HeaderPropertyName from '../../../components/modularcomponents/header/headerpropertyname';
import HeaderPropertyValue from '../../../components/modularcomponents/header/headerpropertyvalue';
import HeaderContainer from '../../../components/modularcomponents/header/headercontainer';
import HeaderItem from '../../../components/modularcomponents/header/headeritem';
import HeaderItems from '../../../components/modularcomponents/header/headeritems';
import HeaderRating from '../../../components/headerdata/headerrating';
import HeaderSummary from '../../../components/modularcomponents/header/headersummary';
import HeaderAUM from '../../../components/modularcomponents/header/headeraum';
import HeaderButtons from '../../../components/modularcomponents/header/headerbuttons';
import { StrategyService } from '../../../services/strategy';

function StrategyHeader(props: {entity: Nullable<Strategy>}): ReactElement {
  const { t } = useTranslation();
  const [expand, setExpand] = useState(false);
  const actionList: PossibleAction[] | undefined = []; // replace undefined by [] to enable action buttons;

  const toggleExpand = () => {
    setExpand(val => !val);
  };

  return (
    <>
      { props.entity &&
        <HeaderContainer expanded={expand} setExpanded={setExpand}>
          <HeaderSummary toggleExpand={toggleExpand}>
            <HeaderTitle toggleExpand={toggleExpand} type='strategy' name={props.entity.name} tags={props.entity.tags} />
            <HeaderButtons id={props.entity._id} service={new StrategyService()} actionList={actionList} />
          </HeaderSummary>
          <HeaderItems>
            <HeaderItem>

              <>
                { props.entity.assetMgr &&
                  <HeaderProperty>
                    <HeaderPropertyName name='Asset Manager ' />
                    <HeaderPropertyValue link>
                      <EntityLink type='assetMgr' id={props.entity.assetMgr._id} name={props.entity.assetMgr.name} openInNewTabIcon/>
                    </HeaderPropertyValue>
                  </HeaderProperty>
                }
              </>

              <HeaderProperty>
                <HeaderPropertyName name={`${t('investMgrs.UpperCaseSimpleLabel')} `} />
                <HeaderPropertyValue link>
                  <EntityLink type='investMgr' id={props.entity.investMgr?._id} name={props.entity.investMgr?.name} openInNewTabIcon/>
                </HeaderPropertyValue>
              </HeaderProperty>

              <HeaderProperty>
                <HeaderAUM aum={props.entity.aum} />
              </HeaderProperty>
            </HeaderItem>

            <HeaderItem>
              <HeaderProperty>
                <HeaderPropertyName name='Asset Class ' />
                <HeaderPropertyValue>{props.entity.assetClass}</HeaderPropertyValue>
              </HeaderProperty>

              <HeaderProperty>
                <HeaderPropertyName name='Geography ' />
                <HeaderPropertyValue>{props.entity.geography}</HeaderPropertyValue>
              </HeaderProperty>

              <HeaderProperty>
                <HeaderPropertyName name='Sub Asset Class ' />
                <HeaderPropertyValue>{props.entity.subAssetClass}</HeaderPropertyValue>
              </HeaderProperty>
            </HeaderItem>

            <HeaderItem>
              <HeaderRating
                rating={props.entity.ratings}
                nextReviews={props.entity.nextReviews}
                entityType={props.entity.latestDdInfo && !props.entity.latestDdInfo.validationDate ? props.entity.latestDdInfo.type : undefined}
                reviewsStopped={props.entity.hasReviewsStopped}
              />
            </HeaderItem>
          </HeaderItems>
        </HeaderContainer>
      }
    </>
  );
}

export default StrategyHeader;
